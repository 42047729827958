

// import "@/Providers/SentryInitialise";
import React from 'react'
import "@/helpers/forceExternalNewWindow";
import '../css/app.scss';
import { createRoot } from 'react-dom/client';
import { createInertiaApp } from '@inertiajs/react';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';

// import images
import.meta.glob([
  '../images/**',
]);
import axios, {AxiosInstance} from 'axios';
window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// Set moment-js language
import moment from "moment";
import 'moment/dist/locale/nl';
import useNMO from "@/helpers/useNMO";
import useHarvest from "@/helpers/useHarvest";
import useGoogleTagManager from "@/helpers/useGoogleTagManager";
import useGdprCookie from "@/helpers/useGdprCookie";
import Providers from "@/providers";
moment.locale('nl');


// Toggle navigation button
document.querySelector('.btn__toggle')?.addEventListener('click', (e) => {
  (e.target as HTMLElement).closest('nav')?.classList.toggle('open');
})

document.querySelectorAll('.arrow')?.forEach((el) => {
  el.addEventListener('click', (e) => {
    (e.target as HTMLElement).closest('li')?.classList.toggle('open');
  })
})

// document.querySelectorAll('section.faq h4')?.forEach((el) => {
//   el.addEventListener('click', (e) => {
//     (e.target as HTMLElement).closest('.question')?.classList.toggle('open');
//   })
// })

document.querySelectorAll('.tabs ul')?.forEach((el) => {

  const tabs = el.children;


  const tabContent = el.closest('.tabs')?.querySelectorAll(':scope > div');

  const setTab = (index: number) => {
    for (let i = 0; i < tabs.length; i++){
      // console.log(i, tabContent)
      tabs?.item(i)?.classList.toggle('open', i === index)
      tabContent?.item(i).classList.toggle('open', i === index)
    }
  }
  setTab(0)

  for (let i = 0; i < tabs.length; i++){
    tabs[i].addEventListener('click', (e) => {
      e.preventDefault();
      setTab(i);
    })
  }

})



// Extend window with custom attributes
declare global {
  interface Window {
    grecaptcha: any;
    KaChing: any;
    axios: AxiosInstance,
    _comscore: object[],
    gdprAppliesGlobally: boolean,
    harvest: any,
    Didomi: any,
  }
}

// NMO Comscore (ScorecardResearch)
useNMO()
// Google Analytics and friends using Tag Manager
useGoogleTagManager()

// gdpr cookie
useGdprCookie()





createInertiaApp({
    resolve: (name) => resolvePageComponent(`./Pages/${name}.tsx`, import.meta.glob('./Pages/**/*.tsx')),
    setup({ el, App, props }) {
        const root = createRoot(el);
      const initProps = props.initialPage.props as any
      // console.log(initProps.legacy_widget)
      root.render(<Providers props={initProps} legacyWidget={initProps.legacy_widget}>
      <App {...props} />
      </Providers>);
    },
    progress: {
        color: '#4B5563',
    },
});
